import React from "react"
import Container from "../Container/Container";

import styles from './ColourBox.module.css'

const ColourBox = (props) => (
    <section className={styles.colourBox}>
        <Container>
            <div className={`${styles.containerFlex} ${styles[props.position]}`}>
                <div className={styles.imgCol} style={{backgroundImage: `url(${props.image})`}}>
                    &nbsp;
                </div>
                <div className={`${styles.textCol} ${styles[props.color]}`} dangerouslySetInnerHTML={{__html: props.description}}></div>
            </div>
        </Container>
    </section>
)

export default ColourBox;