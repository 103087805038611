import React from "react"
import PageBanner from "../components/atoms/PageBanner/PageBanner";
import Button from "../components/atoms/Button/Button";
import { Builder } from '@builder.io/react'

export const BuilderPageBanner = (props) => (
    <PageBanner breadcrumb={props.breadcrumb} title={props.title} color={props.color} imageSize={props.imageSize} imageSrc={props.image} imageOnly={props.imageOnly} textOnly={props.textOnly}>
        <div dangerouslySetInnerHTML={{__html: props.description}}></div>
        {props.buttonLink && (
            <Button href={props.buttonLink} level="primary">{props.buttonLabel}</Button>
        )}
    </PageBanner>
);

Builder.registerComponent(BuilderPageBanner, {
    name: "Page Banner",
    inputs: [
        {
            name: "color",
            friendlyName: 'Background Colour',
            type: "string",
            enum: [
                "blue",
                "red",
                "teal",
                "purple",
                "honey",
                "latte",
                "sky",
                "skyFade",
                "powder",
                "white"
            ],
        },
        {
            name: "breadcrumb",
            friendlyName: 'Breadcrumb',
            type: "string",
        },
        {
            name: "title",
            friendlyName: 'Title',
            type: "string",
        },
        {
            name: "description",
            friendlyName: 'Short description',
            type: "richText",
        },
        {
            name: "buttonLabel",
            friendlyName: 'Button Label',
            type: "string",
        },
        {
            name: "buttonLink",
            friendlyName: 'Button Link',
            type: "string",
        },
        {
            name: "imageSize",
            friendlyName: 'Image Size',
            type: "string",
            enum: [
                "small",
                "medium",
                "large",
            ],
        },
        { 
            name: 'image',
            friendlyName: 'Image',
            type: 'file', 
            allowedFileTypes: ['jpeg', 'png'],
        },
        {
            name: 'imageOnly',
            friendlyName: 'Display image only (centered)',
            type: 'boolean',
            defaultValue: false,
        },
        {
            name: 'textOnly',
            friendlyName: 'Display text only (centered)',
            type: 'boolean',
            defaultValue: false,
        },
    ],
});