import React, { useState, useContext, useEffect } from 'react'
import { navigate } from 'gatsby';
import AuthContext from '../../../context/AuthProvider';

import FormInputField from '../../atoms/FormInputField/FormInputField';
import Button from '../../atoms/Button/Button';
import Dialog from '../../atoms/Dialog/Dialog';
import { getStorage, validateEmail } from '../../../helpers/general';

import styles from './ForgotPasswordForm.module.css';

const validForm = data => {
  if (!data.email) {
    return 'Email is required.';
  }
  if (!validateEmail(data.email)) {
    return 'Invalid email address.';
  }
};

const ForgotPasswordForm = () => {
    const auth = useContext(AuthContext);
    const handleForgotPassword = auth && auth.forgotPassword;
    const userEmail = getStorage('_customEmail');
    const [fields, setFields] = useState({});
    const [init, setInit] = useState({});

    const [msg, setMessage] = useState({
        error: '',
        success: '',
        dialogSuccess: '',
        dialogError: ''
    });

    const [values, setValues] = useState({
        email: ''
    });

    const [dialogOpen, setDialogOpen] = useState(false);
    
    useEffect(() => {
        if (!init && userEmail) {
          setFields({ ...fields, ...{ email: userEmail } });
        }
        setInit(true);
    }, [userEmail, fields, init]);

    const clearDialog = () => {
        setDialogOpen(false);
        navigate(`/login/`);
    };

    const attemptForgotPassword = () => {
        const validationMsg = validForm(values);
        if (validationMsg) {
          setMessage({ error: validationMsg });
          return;
        }
        handleForgotPassword(values.email).then(result => {
          // This returns true regardless as we don't confidently get anything back from BC yet.
          if (result) {
            setMessage({
              dialogSuccess:
                'If your email address exists in the system, you will receive an email with instructions to reset your password.'
            });
            setDialogOpen(true);
          } else {
            setMessage({
              dialogError: 'Sorry, something went wrong. Try again later.'
            });
            setDialogOpen(true);
          }
        });
    };

    const onHandleChange = (id, value) => {
        let tempValues = Object.assign({}, values);
        tempValues[id] = value;
        setValues(tempValues);
    };

    return (
        <>
            <div className="grid grid-100">
                <FormInputField
                id="email"
                type="input"
                labelName="Email Address"
                value={values['email']}
                handleChange={onHandleChange}
                />
            </div>
            {msg.error && <p className={styles.error}>{msg.error}</p>}

            <div className={`row ${styles.buttonWrapp}`}>
                <Button
                type="span"
                level="primary"
                fullWidth={true}
                onClick={() => attemptForgotPassword()}
                >
                Reset Password
                </Button>
            </div>

            <Dialog
                open={dialogOpen}
                title="Reset request made"
                size="sm"
                hideBtnCancel
                disableBackdropClick
                onOk={() => clearDialog()}
            >
                {msg.dialogSuccess && (
                    <p className={styles.dialogContent}>{msg.dialogSuccess}</p>
                )}
                {msg.dialogError && (
                    <p className={styles.dialogContent}>{msg.dialogError}</p>
                )}
            </Dialog>
        </>
    )
}

export default ForgotPasswordForm