import React from "react"
import ContactUsForm from '../components/molecules/ContactUsForm/ContactUsForm';
import { Builder } from '@builder.io/react'

export const BuilderContactUsForm = () => (
    <ContactUsForm />
);

Builder.registerComponent(BuilderContactUsForm, {
    name: "Gorgias Ticket Form",
});