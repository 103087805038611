import React from 'react';
import { graphql } from 'gatsby';
import { builder, BuilderComponent } from '@builder.io/react';
import '@builder.io/widgets';
import { Helmet } from 'react-helmet';
import Layout from "../components/organisms/Layout/Layout"

/* Register builder components */
import '../builderComponents/Button'
import '../builderComponents/Callout'
// import '../builderComponents/CategoryProducts'
import '../builderComponents/ColourBox'
import '../builderComponents/Divider'
import '../builderComponents/DottedBackground'
import '../builderComponents/FeaturedProducts'
import '../builderComponents/ForgotPasswordForm'
import '../builderComponents/GorgiasTicketForm'
import '../builderComponents/LimitAccess'
import '../builderComponents/LoadProducts'
import '../builderComponents/LoginForm'
import '../builderComponents/OfficeInfoBlock'
import '../builderComponents/PageBanner'
import '../builderComponents/RecentPosts'
import '../builderComponents/ReturnsForm'
import '../builderComponents/SignupForm'
import '../builderComponents/SocialIcons'
import '../builderComponents/TradeSignupForm'

import styles from './BuilderPage.module.css';

require('dotenv').config()

builder.init(process.env.BUILDER_PUBLIC_API);

const defaultTitle = 'Spencil';
const defaultDescription = 'Spencil';

function LandingPageTemplate({ data }) {
  const models = data?.allBuilderModels;
  const landingPage = models?.page[0]?.content;
  const pageName = models?.page[0]?.name;

  return (
    <Layout>
      <Helmet>
        <title>{(landingPage && landingPage.data.title) || defaultTitle}</title>
        <meta
          name="description"
          content={
            (landingPage && landingPage.data.description) || defaultDescription
          }
        />
        <body className={`builderPage ${pageName} ${styles.builderPage}`} />
      </Helmet>
      {/** name of the model is landing page, change it if you decided to build*/}
      <section>
        <BuilderComponent
          name="page"
          content={landingPage}
          context={{ apiKey: process.env.BUILDER_PUBLIC_API }} // To support the homepage instagram feed
        />
      </section>
    </Layout>
  );
}

export default LandingPageTemplate;

export const landingPageQuery = graphql`
  query($path: String!) {
    allBuilderModels {
      page(target: { urlPath: $path }, limit: 1, options: { cachebust: true }) {
        content
        name
      }
    }
  }
`;
