// import {useContext} from 'react'
// import AuthContext from '../../../context/AuthProvider';

const LimitAccess = (props) => {
    // const authCtx = useContext(AuthContext);
    // const wholesale = (authCtx && authCtx.isWholesale()) || false;

    // if (
    //     (props.access === 'retail' && wholesale && typeof window !== 'undefined') ||
    //     (props.access === 'wholesale' && !wholesale && typeof window !== 'undefined')
    // ) {
    //     window.location.href = props.redirect;
    // }

    return null;
}
export default LimitAccess