import React, { useState } from 'react';
import { gorgiasApi } from '../../../helpers/gorgias';
import Button from '../../atoms/Button/Button';
import FormInputField from '../../atoms/FormInputField/FormInputField';
import { validateEmail } from '../../../helpers/general';
import Dialog from '../../atoms/Dialog/Dialog';

import styles from './ContactUsForm.module.css';

const validForm = data => {
  if (!data.first_name) {
    return 'First Name is required.';
  }
  if (!data.last_name) {
    return 'Last Name is required.';
  }
  if (!data.email) {
    return 'Email is required.';
  }
  if (!validateEmail(data.email)) {
    return 'Invalid email address.';
  }
  if (!data.phone_number) {
    return 'Phone Number is required.';
  }
  if (!data.message) {
    return 'Message is required.';
  }
};

const ContactUsForm = () => {
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    message: ''
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [msg, setMessage] = useState({
    error: '',
    success: '',
    dialogSuccess: '',
    dialogError: ''
  });

  const onHandleChange = (id, value) => {
    let tempValues = Object.assign({}, values);
    tempValues[id] = value;
    setValues(tempValues);
  };

  const submitContactUsInfo = () => {
    const validationMsg = validForm(values);
    if (validationMsg) {
      setMessage({ error: validationMsg });
      return;
    }
    const { first_name, last_name, email, phone_number, message } = values;
    const body = {
      messages: [
        {
          source: {
            to: [
              {
                name: process.env.GORGIAS_NAME,
                address: process.env.GORGIAS_EMAIL
              }
            ],
            from: {
              name: `${first_name} ${last_name}`,
              address: email
            }
          },
          body_html: `${message} <br /><br />Phone: ${phone_number}`,
          body_text: message,
          subject: 'Contact via Website',
          channel: 'email',
          from_agent: false,
          via: 'api'
        }
      ]
    };

    gorgiasApi(`tickets`, 'POST', body).then(response => {
      if ((response && response.status === 200) || response.status === 201) {
        setMessage({ dialogSuccess: 'Thanks, We received your submission.' });
        setDialogOpen(true);
        setValues({
          first_name: '',
          last_name: '',
          email: '',
          phone_number: '',
          message: ''
        });
      } else {
        setDialogOpen(true);
        setMessage({
          dialogError: 'Sorry, something went wrong. Try again later.'
        });
      }
    });
  };

  return (
    <div>
      {msg.error && <p className={styles.error}>{msg.error}</p>}
      <div
        className={`grid grid-50 ${styles.inputGridGap} ${styles.inputGridWrapp}`}
      >
        <FormInputField
          id="first_name"
          type="input"
          labelName="First Name"
          value={values['first_name']}
          handleChange={onHandleChange}
        />

        <FormInputField
          id="last_name"
          type="input"
          labelName="Last Name"
          value={values['last_name']}
          handleChange={onHandleChange}
        />
      </div>
      <div
        className={`grid grid-50 ${styles.inputGridGap} ${styles.inputGridWrapp}`}
      >
        <FormInputField
          id="email"
          type="input"
          labelName="Email"
          value={values['email']}
          handleChange={onHandleChange}
        />

        <FormInputField
          id="phone_number"
          type="input"
          labelName="Phone Number"
          value={values['phone_number']}
          handleChange={onHandleChange}
        />
      </div>

      <FormInputField
        id="message"
        type="textarea"
        labelName="Message"
        value={values['message']}
        handleChange={onHandleChange}
      />

      <div className={`row ${styles.buttonWrapp}`}>
        <Button
          type="span"
          level="primary"
          fullWidth={true}
          onClick={() => submitContactUsInfo()}
        >
          Submit
        </Button>
      </div>

      <Dialog
        hideBtnCancel={true}
        size="sm"
        open={dialogOpen}
        hideBtnOk={false}
        title="Contact Us Message"
        onCancel={() => setDialogOpen(false)}
      >
        {msg.dialogSuccess && (
          <p className={styles.dialogContent}>{msg.dialogSuccess}</p>
        )}
        {msg.dialogError && (
          <p className={styles.dialogContent}>{msg.dialogError}</p>
        )}
      </Dialog>
    </div>
  );
};

export default ContactUsForm;
