import React from "react"
import ColourBox from "../components/atoms/ColourBox/ColourBox";
import { Builder } from '@builder.io/react'

export const BuilderColourBox = (props) => <ColourBox position={props.position} image={props.image} color={props.color} description={props.description} />

Builder.registerComponent(BuilderColourBox, {
    name: "Colour Box Content",
    inputs: [
        { 
            name: 'image',
            friendlyName: 'Image',
            type: 'file', 
            allowedFileTypes: ['jpeg', 'png'],
        },
        {
            name: "description",
            friendlyName: 'Short description',
            type: "richText",
        },
        {
            name: "color",
            friendlyName: 'Background Colour',
            type: "string",
            enum: [
                "blue",
                "red",
                "teal",
                "purple",
                "honey",
                "latte",
                "sky",
                "skyFade",
                "powder",
                "white"
            ],
        },
        {
            name: "position",
            friendlyName: 'Image Position',
            type: "string",
            enum: [
                "left",
                "right"
            ],
        }
    ]
});