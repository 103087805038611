import React from 'react';
import OfficeDetails from '../../atoms/OfficeDetails/OfficeDetails';
// import Map from '../../atoms/Map/Map';

// import styles from './ContactInfo.module.css';

const ContactInfo = (props) => {
  return (
    <>
      <OfficeDetails
        officeTitle={props.officeTitle}
        officeDetails={props.officeDetails}
        serviceTitle={props.serviceTitle}
        serviceDetails={props.serviceDetails}
      />
      {/* <div className={styles.mapContainer}>
        <Map />
      </div> */}
    </>
  );
};

export default ContactInfo;
