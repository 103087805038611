import React from "react"
import FeaturedProducts from "../components/organisms/FeaturedProducts/FeaturedProducts";
import { Builder } from '@builder.io/react'

export const BuilderFeaturedProducts = (props) => (
    <FeaturedProducts buttonLabel={props.label} buttonLink={props.link} />
);

Builder.registerComponent(BuilderFeaturedProducts, {
    name: "Featured Products",
    inputs: [
        {
          name: "label",
          friendlyName: 'Button label',
          type: "string",
          defaultValue: "Click me",
        },
        {
          name: "link",
          friendlyName: 'Button link',
          type: "string",
          defaultValue: "/",
        },
      ],
});