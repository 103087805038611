import React from "react"
import DottedBackground from '../components/atoms/DottedBackground/DottedBackground'
import { Builder } from '@builder.io/react'

export const BuilderDottedBackground = (props) => (
    <DottedBackground>{props.text}</DottedBackground>
);

Builder.registerComponent(BuilderDottedBackground, {
    name: "Dotted Text",
    inputs: [
      {
        name: "text",
        friendlyName: 'Text',
        type: "string",
      },
    ],
});