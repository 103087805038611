import React from "react"
import Divider from '../components/atoms/Divider/Divider'
import { Builder } from '@builder.io/react'

const colors = {
    blue: "#0038c9",
    red: "#f73d1f",
    teal: "#00a1b4",
    purple: "#d9bae8",
    honey: "#fcbf33",
    latte: "#faebd9",
    sky: "#63cfe3",
    skyFade: "#d0f1f7",
    powder: "#f5c2cc",
    white: "#ffffff",
    lightRed: "#f3767f",
}

export const BuilderDivider = (props) => (
    <div style={{color: colors[props.color]}}>
        <Divider shape={props.shape} />
    </div>
);

Builder.registerComponent(BuilderDivider, {
    name: "Divider",
    inputs: [
        {
            name: "shape",
            friendlyName: 'Shape',
            type: "string",
            enum: [
                "cloud",
                "teeth",
            ],
        },
        {
            name: "color",
            friendlyName: 'Colour',
            type: "string",
            enum: [
                "blue",
                "red",
                "teal",
                "purple",
                "honey",
                "latte",
                "sky",
                "skyFade",
                "powder",
                "white",
                "lightRed"
            ],
        },
    ],
});