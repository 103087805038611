import React, { useEffect, useState } from 'react';

import { bcApi } from '../../../helpers/bigcommerce';

import Slider from '../../atoms/Slider/Slider';
import ProductGrid from '../ProductGrid/ProductGrid';
import ProductCard from '../../molecules/ProductCard/ProductCard';
import Button from '../../atoms/Button/Button';
import styles from './LoadProducts.module.css';

const defaultResponsive = [
  {
    breakpoint: 1023,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true
    }
  },
  {
    breakpoint: 767,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true
    }
  },
  {
    breakpoint: 500,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    }
  }
];

function LoadProducts(props) {
  const [fetchingProducts, setFetchingProducts] = useState(false);
  const [displayProducts, setDisplayProducts] = useState(false);

  useEffect(() => {
    if (!displayProducts && !fetchingProducts) {
        setFetchingProducts(true);

        const url = new URL('catalog/products', 'https://placehold/');
        let runQuery = false;
        if (props.definitionType === 'Category ID') {
            runQuery = true;
            url.searchParams.set('categories:in', props.definitions);
        } else if (props.definitionType === 'Individual SKUs') {
            runQuery = true;
            url.searchParams.set('sku:in', props.definitions);
        }
        if (runQuery) {
            url.searchParams.set('include', 'variants,images,custom_fields');
            bcApi(`catalog/products${url.search}`).then(response => {
                if (response.status === 200 && 'data' in response.response) {
                    setDisplayProducts(response.response.data);
                    setFetchingProducts(false);
                }
            })
        }
    }
  }, [displayProducts, fetchingProducts, props]);

  return (
    <div data-loadproducts className={`${styles.loadProductContainer} ${props.transparent ? styles.transparentCards : ''}`}>
        {props && props.displayType === 'Slider' && (
            <Slider
                infinite={false}
                lazyLoad={true}
                slidesToShow={(props && props.columns) || 4}
                slidesToScroll={(props && props.columns) || 1}
                responsive={props.responsive || defaultResponsive}
            >
                {displayProducts && displayProducts.map(node => (
                <ProductCard key={node.id} data={node} />
                ))}
            </Slider>
        )}

        {props && props.displayType === 'Grid' && (
            <ProductGrid products={displayProducts} columns={(props && props.columns) || 4} loadInventory={true} />
        )}

        {props.buttonLink && (
            <div className="flex-center">
            <Button href={props.buttonLink} level="primary">
                {props.buttonLabel}
            </Button>
            </div>
        )}
    </div>
  );
}

export default LoadProducts
