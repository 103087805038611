import React from 'react';
import Divider from '../Divider/Divider';

import styles from './OfficeDetails.module.css';

const OfficeDetails = ({
  officeTitle,
  officeDetails,
  serviceTitle,
  serviceDetails
}) => {
  return (
    <div className={styles.contactInfoContainer}>
      <div className={styles.contactInfoBox}>
        {officeTitle && <div className={styles.boldTitle}>{officeTitle}</div>}
        {officeDetails && (
          <p className={styles.officeDetails}>{officeDetails}</p>
        )}

        {serviceTitle && <div className={styles.boldTitle}>{serviceTitle}</div>}
        {serviceDetails && <p>{serviceDetails}</p>}
      </div>
      <div className={styles.defaultDiver}>
        <Divider shape="cloud" />
      </div>
    </div>
  );
};

export default OfficeDetails;
