import React from "react"
import Callout from '../components/molecules/Callout/Callout'
import { Builder } from '@builder.io/react'

export const BuilderCallout = (props) => (
    <Callout design={props.style} color={props.color} link={props.link}>{props.label}</Callout>
);

Builder.registerComponent(BuilderCallout, {
    name: "Callout",
    inputs: [
      {
        name: "label",
        friendlyName: 'Text in Callout',
        type: "string",
        defaultValue: "Highlight!",
      },
      {
        name: "style",
        friendlyName: 'Callout style',
        type: "string",
        enum: [
            "starburst",
            "starburst2",
            "tag",
            "tag2",
        ],
      },
      {
        name: "color",
        friendlyName: 'Callout colour',
        type: "string",
        enum: [
            "blue",
            "red",
            "teal",
            "purple",
            "honey",
            "latte",
            "sky",
            "skyFade",
            "powder",
        ],
      },
      {
        name: "link",
        friendlyName: 'URL to go to if clicked',
        type: "string",
      },
    ],
});