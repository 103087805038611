import React from "react"
import LimitAccess from "../components/atoms/LimitAccess/LimitAccess";
import { Builder } from '@builder.io/react'

export const BuilderLimitAccess = (props) => (
    <LimitAccess access={props.access} redirect={props.redirect} />
);

Builder.registerComponent(BuilderLimitAccess, {
    name: "Limit page access",
    inputs: [
        {
            name: "access",
            friendlyName: 'Who has access to this page?',
            type: "string",
            enum: [
                "retail"
            ],
        },
        {
            name: "redirect",
            friendlyName: 'Redirect unauthorised to (full path inc domain)',
            type: "string",
        },
    ],
});