import React, { useEffect, useMemo, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Slider from '../../atoms/Slider/Slider';
import ProductCard from '../../molecules/ProductCard/ProductCard';
import Button from '../../atoms/Button/Button';
import styles from './FeaturedProducts.module.css';

const defaultResponsive = [
  {
    breakpoint: 1023,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3
    }
  },
  {
    breakpoint: 767,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2
    }
  },
  {
    breakpoint: 500,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2
    }
  },
  {
    breakpoint: 350,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }
];

function FeaturedProducts(props) {
  const { limit } = props;
  const [products, setProducts] = useState(false);
  const [displayProducts, setDisplayProducts] = useState(false);

  const sectionQuery = useStaticQuery(graphql`
    query {
      featuredProducts: allBigCommerceProducts(filter: {is_featured: {eq: true}}) {
        edges {
          node {
            id
            entityId: bigcommerce_id
            name
            sku
            price
            calculated_price
            retail_price
            sale_price
            brand_id
            categories
            warranty
            custom_url {
              url
            }
            images {
              url_standard
              url_thumbnail
              url_zoom
              id
              description
              image_file
              is_thumbnail
              sort_order
            }
            variants {
              product_id
              id
              sku
            }
            custom_fields{
              name
              value
            }
          }
        }
      }
    }
  `);

  useMemo(() => {
    if (!products) {
      setProducts(sectionQuery?.featuredProducts.edges || []);
    }
  }, [sectionQuery, products]);

  useEffect(() => {
    if (!displayProducts) {
      let newProducts = products.filter(product => product.node.custom_fields.filter(customField => (customField.name === 'PackQty' || customField.name === 'RRP')).length === 0);

      if (limit) {
        newProducts = newProducts.slice(0, limit);
      }
      setDisplayProducts(newProducts);
    }
  }, [products, displayProducts, limit]);

  return (
    <div data-featuredproducts data-producttypes={'retail'} className={styles.featuredContainer}>
      <Slider
        infinite={true}
        lazyLoad={true}
        slidesToShow={4}
        slidesToScroll={4}
        responsive={props.responsive || defaultResponsive}
      >
        {displayProducts && displayProducts.map(node => (
          <ProductCard key={node.node.id} data={node.node} />
        ))}
      </Slider>

      {props.buttonLink && (
        <div className="flex-center">
          <Button href={props.buttonLink} level="primary">
            {props.buttonLabel}
          </Button>
        </div>
      )}
    </div>
  );
}

export default FeaturedProducts;
