import React from "react"
import Button from '../components/atoms/Button/Button'
import { Builder } from '@builder.io/react'

export const BuilderButton = (props) => (
    <Button href={props.link} target={props.target} theme={props.color} alternative={props.color === 'white'} level="primary" type="a">{props.label}</Button>
);

Builder.registerComponent(BuilderButton, {
    name: "Spencil Button",
    inputs: [
      {
        name: "label",
        friendlyName: 'Button label',
        type: "string",
        defaultValue: "Click me",
      },
      {
        name: "link",
        friendlyName: 'Button link',
        type: "string",
        defaultValue: "/",
      },
      {
        name: "color",
        friendlyName: 'Colour',
        type: "string",
        enum: [
            "blue",
            "red",
            "teal",
            "purple",
            "honey",
            "latte",
            "sky",
            "skyFade",
            "powder",
            "white"
        ],
      },
      {
        name: "target",
        friendlyName: 'Open link in new tab',
        type: "boolean",
        defaultValue: false,
      },
    ],
});