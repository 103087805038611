import React from 'react'

import styles from './DottedBackground.module.css'

const DottedBackground = ({children}) => (
    <div className={styles.dottedBackground}>
        <span>{children}</span>
    </div>
)

export default DottedBackground
