import React, { useState, useContext, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { isEmpty } from 'lodash';
import { scroller } from 'react-scroll';

import AuthContext from '../../../context/AuthProvider';
import Button from "../../atoms/Button/Button"
import Loader from '../../atoms/Loader/Loader';
import Dialog from "../../atoms/Dialog/Dialog"
import { bcApi } from '../../../helpers/bigcommerce';
import { getStorage, useScript, validateEmail } from '../../../helpers/general'
import { gorgiasApi } from '../../../helpers/gorgias';
import { subscribeForm } from '../../../helpers/klaviyo'

import styles from './TradeSignupForm.module.css';

require('dotenv').config();

let autoComplete;

const TradeSignupForm = () => {
    const auth = useContext(AuthContext);
    const handleRegistration = auth && auth.signup;
    const userEmail = getStorage('_customEmail');
    const [init, setInit] = useState(false);
    const [fields, setFields] = useState({});
    const [dialogMessage, setDialogMessage] = useState(false);
    const [formFields, setFormFields] = useState({});
    const [requiredFields, setRequiredFields] = useState({});
    const [displayValidation, setDisplayValidation] = useState(false);
    const [customerGroup, setCustomerGroup] = useState(0);
    const [countries, setCountries] = useState([]);
    const [countryStates, setCountryStates] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [query, setQuery] = useState('');
    const [acInit, setAcInit] = useState(false);
    const [autocompleteData, setAutocompleteData] = useState({});
    const outputFields = [];
    // field_39 = Website URL
    // field_38 = Tell us more about your interest
    const optionalFields = ['address2', 'field_39', 'field_38'];

    const autoCompleteRef = useRef(null);

    const dataQuery = useStaticQuery(graphql`
        query {
            allBigCommerceStorefrontFormFields {
                edges {
                node {
                    customerAccount {
                        type
                        label
                        name
                        required
                        default
                        fieldType
                        options {
                            helperLabel
                            items {
                                label
                                value
                            }
                        }
                    }
                    shippingAddress {
                        type
                        label
                        name
                        required
                        default
                        fieldType
                        options {
                            helperLabel
                            items {
                                label
                                value
                            }
                        }
                    }
                }
                }
            }
            allBigCommerceCountries {
                edges {
                node {
                    country
                    country_iso2
                    states {
                        resource
                    }
                }
                }
            }
        }
    `);

    const fieldsMap = [
        {
            title: '',
            fields: {
                'company': null,
                'custom_Your business role': null,
                'custom_Company ABN': 'ABN',
                'phone': null,
                'firstName': null,
                'lastName': null,
                'email': null,
                'password': null,
            }
        },
        {
            title: 'Address',
            fields: {
                'addressAutocomplete': null,
                'address1': null,
                'address2': null,
                'city': null,
                'postalCode': null,
                'countryCode': null,
                'stateOrProvince': null,
            }
        },
        {
            title: 'Your store',
            fields: {
                'custom_Company type': 'What kind of store do you operate?',
                'custom_Does your business operate a physical or online shop?': null,
                'custom_Website URL': null,
                'custom_Tell us more about your interest in Spencil for your store': null,
            }
        }
    ];

    useEffect(() => {

        if (!init && userEmail) {
            setFields({...fields, ...{email: userEmail}})
        }

        if (!init && Object.keys(formFields).length === 0) {
            const formFieldsData = {};
            dataQuery.allBigCommerceStorefrontFormFields.edges[0].node.customerAccount.map(field => {
                const fieldKey = field.name.startsWith('field_') ? `custom_${field.label}` : field.name;
                formFieldsData[fieldKey] = field;
                return true;
            });
            dataQuery.allBigCommerceStorefrontFormFields.edges[0].node.shippingAddress.map(field => {
                const fieldKey = field.name.startsWith('field_') ? `custom_${field.label}` : field.name;
                formFieldsData[fieldKey] = field;
                return true;
            });
            setFormFields({...formFieldsData});
        }

        if (!init && countries.length === 0) {
            setCountries(dataQuery.allBigCommerceCountries.edges);
        }

        if (!init && customerGroup === 0) {
            setCustomerGroup(dataQuery.bigCommerceCustomerGroups.id);
            setFields({...fields, ...{customer_group_id: dataQuery.bigCommerceCustomerGroups.id}})
        }

        setInit(true);
    }, [userEmail, fields, init, dataQuery, formFields, countries, customerGroup]);

    useEffect(() => {
        setFields(data => ({...data, ...autocompleteData}));
    }, [autocompleteData]);

    const validateForm = () => {
        const invalid = Object.keys(requiredFields).filter(required => {
            let isInvalid = (!(required in fields) || fields[required] === '');
            if (required === 'email' && required in fields && fields[required] !== '') {
                isInvalid = !validateEmail(fields[required]);
            }

            if (isInvalid && requiredFields[required]) {
                const requiredField = {};
                requiredField[required] = false;
                setRequiredFields({ ...requiredFields, ...requiredField });
            }
            return isInvalid;
        });
        if (invalid.length > 0) {
            console.warn(invalid);
            setDisplayValidation(true);
            scroller.scrollTo('signup-form', {
                duration: 800,
                delay: 0,
                offset: -200,
                smooth: 'easeInOutQuart',
            });
            return false;
        }

        return true;
    }

    const buildGorgiasMessage = (type, rawFields) => {
        const nl = type === 'html' ? '<br />' : '\r\n';
        const finalFields = Object.keys(rawFields).filter(fieldKey => ['customer_group_id', 'password', 'first_name', 'last_name', 'addresses'].indexOf(fieldKey) === -1);
        const message = `
            A Request for a stockist account has been made. Details below were submitted:${nl}${nl}
            ${finalFields.map(fieldKey => `${type === 'html' ? '<strong>' : ''}${fieldKey.replace('custom_', '')}:${type === 'html' ? '</strong>' : ''} ${rawFields[fieldKey]}`).join(nl)}
            ${nl}${nl}Please review and contact the potential stockist to evaluate whether they would be a good fit. If approved, please follow the approval process.
        `;

        return message;
    }

    const attemptRegistration = (e) => {
        e.preventDefault();
        const valid = validateForm();
        setIsSending(true);
        if (valid) {
            /* Create BC Account */
            const rawFields = {...fields};
            handleRegistration({...fields}).then(() => {
                /* BC account successfully created - Create Gorgias ticket for approvals */
                const messageHTML = buildGorgiasMessage('html', rawFields);
                const messageText = buildGorgiasMessage('text', rawFields);
                const body = {
                    messages: [
                      {
                        source: {
                          to: [
                            {
                              name: process.env.GORGIAS_NAME,
                              address: process.env.GORGIAS_EMAIL
                            }
                          ],
                          from: {
                            name: `${rawFields.firstName} ${rawFields.lastName}`,
                            address: rawFields.email
                          }
                        },
                        body_html: messageHTML,
                        body_text: messageText,
                        subject: `ACTION: Request for Stockist Approval by ${rawFields.company}`,
                        channel: 'email',
                        from_agent: false,
                        via: 'api'
                      }
                    ]
                };
              
                gorgiasApi(`tickets`, 'POST', body).then(() => {
                    /* Gorgias ticket created - Add email to Klavyio list */
                    const listId = 'Yxvyep';
                    const klvFields = {
                        email: rawFields.email,
                        first_name: rawFields.firstName,
                        last_name: rawFields.lastName,
                    }
    
                    subscribeForm(listId, klvFields).then(() => {
                        /* Klavyio list added to - thank the customer */
                        setIsSubmitted(true);
                    });
                });
                
            }).catch(error => {
                setIsSending(false);
                if (typeof error === "object") {
                    console.log(error);
                } else {
                    let errorMsg = error;
                    if (errorMsg.indexOf('already in use') > -1) {
                        errorMsg = `${error}. Please <a href="/contact-us/">contact us</a>`;
                    }
                    setDialogMessage(errorMsg);
                }
            });
        } else {
            setIsSending(false);
        }
    }

    const clearDialog = () => {
        setDialogMessage(false);
    }

    const setFieldValue = (fieldName, fieldValue) => {
        setDisplayValidation(false);
        const newField = {};
        newField[fieldName] = fieldValue;
        setFields({...fields, ...newField});
        const requiredField = {};
        requiredField[fieldName] = (fieldName === 'email') ? validateEmail(fieldValue) : fieldValue !== '';
        setRequiredFields({ ...requiredFields, ...requiredField });
    }

    const fetchStates = evt => {
        const { value: countryIso } = evt.target;
    
        let countryObject = false;
        countries.map(country => {
          if (country.node.country_iso2 === countryIso) {
            countryObject = country;
          }
          return true;
        });
        setIsFetching(true);
    
        const endpoint = countryObject.node.states.resource.substring(1);
        bcApi(`${endpoint}?limit=250`, 'GET', null, 2).then(
          ({ response, status }) => {
            if (status === 200) {
              setCountryStates(response);
            } else {
              setCountryStates([]);
            }
            setIsFetching(false);
          }
        );
    };

    useScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API}&libraries=places`, 'google', () => {
        if (typeof document !== 'undefined') {
            const autocompleteField = document.getElementById('autocompleteField');
            if (autocompleteField && !acInit) {
                setAcInit(true);
                initialiseAutoComplete(setQuery, setAutocompleteData, autoCompleteRef);
            }
        }
    });

    const initialiseAutoComplete = (updateQuery, updateAutocompleteData, autoCompleteRef) => {
        autoComplete = new window.google.maps.places.Autocomplete(
          autoCompleteRef.current,
          { componentRestrictions: { country: "au" } }
        );
        autoComplete.setFields(["address_components", "formatted_address"]); // specify what properties we will get from API
        // add a listener to handle when the place is selected
        autoComplete.addListener("place_changed", () =>
          handlePlaceSelect(updateQuery, updateAutocompleteData)
        );
    }
    
    const handlePlaceSelect = async (updateQuery, updateAutocompleteData) => {
        const addressObject = autoComplete.getPlace();
        const query = addressObject.formatted_address;
        updateQuery(query);
        const addressComponents = {};
        addressObject.address_components.map(component => {
          const types = component.types.filter(type => type !== 'political');
          addressComponents[types[0]] = component;
          return true;
        });
        
        updateAutocompleteData({
          address1: `${addressComponents.street_number?.long_name || ''} ${addressComponents.route?.long_name} `,
          city: addressComponents.locality?.long_name,
          countryCode: addressComponents.country?.short_name,
          postalCode: addressComponents.postal_code?.long_name,
          stateOrProvince: addressComponents.administrative_area_level_1?.long_name
        });
    }

    if (isSubmitted) {
        return (
            <div className={styles.thankYou}>
                <h6>Thank you for your interest</h6>
                <p>We love onboarding new stockists to the Spencil family. We will now review your application and be in touch soon to discuss you being a Spencil stockist.</p>
            </div>
        )
    } else {
        let fieldCount = 0;
        return (
            <form id="signup-form" noValidate onSubmit={(e) => attemptRegistration(e)}>
                {fieldsMap && fieldsMap.map((section, sectionIndex) => (
                    <div key={sectionIndex} className={styles.fieldSection}>
                        {section.title !== '' && (
                            <h6>{section.title}</h6>
                        )}
                        <div className={styles.mobileGrid}>
                            {Object.keys(section.fields).map((field, fieldIndex) => {
                                const thisField = field in formFields && formFields[field];
                                if (thisField && outputFields.indexOf(field) === -1) {
                                    const fieldLabel = section.fields[field] !== null ? section.fields[field] : thisField.label;
                                    const fieldKey = field;
                                    outputFields.push(field);
                                    const isRequired = optionalFields.indexOf(thisField.name) === -1;
                                    if (Object.keys(fields).indexOf(fieldKey) === -1) {
                                        const tempObj = {};
                                        tempObj[fieldKey] = '';
                                        setFields({ ...fields, ...tempObj });
                                    }
                                    if (Object.keys(requiredFields).indexOf(fieldKey) === -1 && isRequired) {
                                        const tempObj = {};
                                        tempObj[fieldKey] = false;
                                        setRequiredFields({ ...requiredFields, ...tempObj });
                                    }

                                    const classesArr = [styles.formInput];
                                    let displayFieldValidation = false;
                                    if (displayValidation && fieldKey in requiredFields && !requiredFields[fieldKey]) {
                                        classesArr.push(styles.invalid);
                                        displayFieldValidation = true;
                                    }
                                    
                                    const classes = classesArr.join(' ');
                                    fieldCount++;
                                    return (
                                        <div key={fieldIndex} className={`formField ${styles.mobileFormField} ${thisField.fieldType === 'multiline' ? styles.stretchFormField : ''}`}>
                                            <label htmlFor={thisField.name} className={styles.formLabel}>{fieldLabel}</label>
                                            {(thisField.fieldType === "text" || thisField.fieldType === null) && thisField.name !== "email" && thisField.name !== "stateOrProvince" && (
                                                <>
                                                    {displayFieldValidation && (
                                                        <span className={styles.errorMsg}>Please fill</span>
                                                    )}
                                                    <input className={classes} required={isRequired} type="text" name={thisField.name} tabIndex={fieldCount} value={fields[fieldKey]} onChange={(e) => setFieldValue(fieldKey, e.target.value)} />
                                                </>
                                            )}
                                            {thisField.fieldType === "text" && thisField.name === "email" && (
                                                <>
                                                    {displayFieldValidation && (
                                                        <span className={styles.errorMsg}>Provide a valid email</span>
                                                    )}
                                                    <input className={classes} required={isRequired} placeholder={userEmail} type="email" name={thisField.name} tabIndex={fieldCount} value={fields[fieldKey]} onChange={(e) => setFieldValue(fieldKey, e.target.value)} />
                                                </>
                                            )}
                                            {thisField.fieldType === "multiline" && (
                                                <>
                                                    {displayFieldValidation && (
                                                        <span className={styles.errorMsg}>Please fill</span>
                                                    )}
                                                    <textarea className={classes} required={isRequired} name={thisField.name} tabIndex={fieldCount} value={fields[fieldKey]} onChange={(e) => setFieldValue(fieldKey, e.target.value)} />
                                                </>
                                            )}
                                            {thisField.fieldType === "password" && (
                                                <>
                                                    {displayFieldValidation && (
                                                        <span className={styles.errorMsg}>Please set a password</span>
                                                    )}
                                                    <input className={classes} required={isRequired} type="password" name={thisField.name} tabIndex={fieldCount} value={fields[fieldKey]} onChange={(e) => setFieldValue(fieldKey, e.target.value)} />
                                                </>
                                            )}
                                            {thisField.fieldType === "dropdown" && thisField.name !== "countryCode" && thisField.options !== null && (
                                                <>
                                                    {displayFieldValidation && (
                                                        <span className={styles.errorMsg}>Please select</span>
                                                    )}
                                                    <select name={thisField.name} required={isRequired} className={classes} tabIndex={fieldCount} defaultValue={fields[fieldKey]} onBlur={(e) => setFieldValue(fieldKey, e.target.value)}>
                                                        <option>{thisField.options.helperLabel}</option>
                                                        {thisField.options.items !== null && thisField.options.items.length > 0 && thisField.options.items.map((option, optionIndex) => (
                                                            <option key={optionIndex} value={thisField.name.startsWith('field_') ? option.label : option.value}>{option.label}</option>
                                                        ))}
                                                    </select>
                                                </>
                                            )}
                                            {thisField.fieldType === "dropdown" && thisField.name === "countryCode" && (
                                                <>
                                                    {displayFieldValidation && (
                                                        <span className={styles.errorMsg}>Please select</span>
                                                    )}
                                                    <select name={thisField.name} required={isRequired} className={classes} tabIndex={fieldCount} defaultValue={fields[fieldKey]} onBlur={(e) => setFieldValue(fieldKey, e.target.value)} onChange={fetchStates}>
                                                        <option>{thisField.options.helperLabel}</option>
                                                        {countries.map((country, countryIndex) => (
                                                            <option key={countryIndex} value={country.node.country_iso2}>
                                                            {country.node.country}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </>
                                            )}
                                            {thisField.name === "stateOrProvince" && (
                                                <>
                                                    {!isEmpty(countryStates) && (
                                                        <>
                                                            {displayFieldValidation && (
                                                                <span className={styles.errorMsg}>Please select</span>
                                                            )}
                                                            <select
                                                            required={isRequired}
                                                            className={classes} 
                                                            name="stateOrProvince"
                                                            defaultValue={fields[fieldKey]}
                                                            tabIndex={fieldCount}
                                                            onBlur={(e) => setFieldValue(fieldKey, e.target.value)}
                                                            >
                                                                <option>Select a state...</option>
                                                                {countryStates.map((state, stateIndex) => (
                                                                    <option key={stateIndex} value={state.state}>
                                                                    {state.state}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </>
                                                    )}
                                                    {isEmpty(countryStates) && (
                                                        <>
                                                            {displayFieldValidation && (
                                                                <span className={styles.errorMsg}>Please select a country</span>
                                                            )}
                                                            <input
                                                            required={isRequired}
                                                            className={classes} 
                                                            type="text"
                                                            name="stateOrProvince"
                                                            value={fields[fieldKey]}
                                                            tabIndex={fieldCount}
                                                            onChange={(e) => setFieldValue(fieldKey, e.target.value)}
                                                            />
                                                        </>
                                                    )}
                                                    {isFetching && (
                                                        <div className={styles.spinnerWrapper}>
                                                            <Loader />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    )
                                } else if (field === "addressAutocomplete") {
                                    fieldCount++;
                                    return (
                                        <div key={fieldIndex} className={`formField ${styles.mobileFormField} ${styles.stretchFormField}`}>
                                            {(!('address1' in fields) || fields.address1 === '') && (
                                                <>
                                                    <div className="formField">
                                                        <label htmlFor="autcompleteField" className={styles.formLabel}>Enter your address</label>
                                                        <input type="text" autoComplete="new-password" tabIndex={fieldCount} name="autcompleteField" id="autocompleteField" ref={autoCompleteRef} onChange={event => setQuery(event.target.value)} value={query} />
                                                    </div>
                                                    <div className="formField">
                                                        <span className="label">Or enter below:</span>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    )
                                }
                                return true;
                            })}
                        </div>
                    </div>
                ))}
                
                <div className="formField">
                    <Button className={styles.formSubmit} level="primary" type="buttonSubmit" disabled={isSending}>{isSending ? 'Sending...' : 'Apply'}</Button>
                </div>
                <Dialog open={dialogMessage ? true : false} title="Account creation unsuccessful" size="sm" hideBtnCancel disableBackdropClick onOk={() => clearDialog()} htmlInString={true}>{dialogMessage}</Dialog>
            </form>
        );
    }
};

export default TradeSignupForm;
