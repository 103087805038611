import React from "react"
import ContactInfo from '../components/molecules/ContactInfo/ContactInfo';
import { Builder } from '@builder.io/react'

export const BuilderOfficeInfoBlock = (props) => (
    <ContactInfo 
        officeTitle={props.officeTitle}
        officeDetails={props.officeDetails}
        serviceTitle={props.serviceTitle}
        serviceDetails={props.serviceDetails}
    />
);

Builder.registerComponent(BuilderOfficeInfoBlock, {
    name: "Office Info Block",
    inputs: [
        {
            name: "officeTitle",
            friendlyName: 'Office Title',
            type: "string",
        },
        {
            name: "officeDetails",
            friendlyName: 'Office Address',
            type: "string",
        },
        {
            name: "serviceTitle",
            friendlyName: 'Secondary Title',
            type: "string",
        },
        {
            name: "serviceDetails",
            friendlyName: 'Secondary Information',
            type: "string",
        },
    ],
});